export const environment = {
  production: false,
  panel: "radheexch_",
  // domain: "jarvisexch.com",
  // baseUrl: "https://jarvisexch.com",
  domain: "oceanexch9.com",
  baseUrl: "https://api.oceanexch9.com",
  // domain: "parker777.com",
  // baseUrl: "https://api.parker777.com",
  pollers: {
      oddsData: 600,
      listOddsData: 10000,
      scoresData: 1000,
  },
  noDataMsg: 'No Data Found',
  imgPrefix: '/radheexch_client',
  guestEnable: false,
  darkMode: false,
  horseRaceJockeyImgPrefix: 'https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/',
  stakes: { "stake_name_1": "100", "stake_name_2": "5000", "stake_name_3": "500", "stake_name_4": "25000", "stake_name_5": "50000", "stake_name_6": "100000", "stake_name_7": "500000", "stake_name_8": "1000000", "stake_amount_1": 100, "stake_amount_2": 5000, "stake_amount_3": 500, "stake_amount_4": 25000, "stake_amount_5": 50000, "stake_amount_6": 100000, "stake_amount_7": 500000, "stake_amount_8": 1000000 },
  host: {
      domain: '',
      name: '',
      dName: '',
      ext: '',
      theme: '',
      email: '',
      pusher: {
          key: '',
          cluster: ''
      },
      themePath: [''],
      guestEnable: false,
      ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      afLoginRedirectUrl: '',
      newHomePage: false,
      isCustomBanner: false,
      demoLogin: false,
  },
  hosts: [
      {
          domain: 'localhost',
          name: 'localhost',
          dName: 'LocalHost',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5c222479a9f50216f9ce',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-master.css'],
          guestEnable: true,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
      },
      {
          domain: 'oceanexch9.com',
          name: 'oceanexch9',
          dName: 'oceanexch9',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5c222479a9f50216f9ce',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-master.css'],
          guestEnable: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
      },
      {
          domain: 'cricbook9.com',
          name: 'cricbook9',
          dName: 'cricbook9',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5c222479a9f50216f9ce',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-master.css'],
          guestEnable: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
      },
      {
          domain: '21libra.com',
          name: '21libra',
          dName: '21libra',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5c222479a9f50216f9ce',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-master.css'],
          guestEnable: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
      },
      {
          domain: 'divineexch9.com',
          name: 'divineexch9',
          dName: 'divineexch9',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5c222479a9f50216f9ce',
              cluster: 'eu'
          },
          themePath: ['assets/css/theme-files/theme-master.css'],
          guestEnable: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: false,
          demoLogin: false,
      },
      {
        domain: 'oceanexch9.co',
        name: 'oceanexch9',
        dName: 'oceanexch9',
        ext: '.co',
        theme: 'blue-body',
        email: '',
        pusher: {
            key: '5c222479a9f50216f9ce',
            cluster: 'eu'
        },
        themePath: ['assets/css/theme-files/theme-master.css'],
        guestEnable: false,
        ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
        afLoginRedirectUrl: '/dashboard',
        newHomePage: false,
        isCustomBanner: false,
        demoLogin: false,
    },
  ]
};
